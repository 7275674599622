export const Supporters = () => {
    return (
        <div
            className="content"
            style={{ color: "white", padding: 30, marginLeft: 30 }}
        >
            <h1 style={{ color: "white" }}>Supporters</h1>
            Luke Cleland
            <br />
            Luke Cleland
            <br />
            Luke Cleland
            <br />
            Luke Cleland
            <br />
            Luke Cleland
            <br />
            Luke Cleland
            <br />
            Luke Cleland
            <br />
            Luke Cleland
            <br />
            Luke Cleland
            <br />
            Luke Cleland
            <br />
            Luke Cleland
            <br />
            Luke Cleland
            <br />
            Luke Cleland
            <br />
        </div>
    );
};
